@import 'tachyons';
@import '~blip-toolkit/dist/scss/main';
@import '~blip-ds/dist/collection/styles/_fonts.scss';
@import '~blip-ds/dist/collection/styles/_colors.scss';

// plugin template scss
@import 'variables';
@import 'mixins';
@import 'utilities';

body {
    margin: 0;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.customPeriod {
    display: flex;
    margin-bottom: 10px;
    justify-content: left;
}

.customPeriodLabel {
    margin-right: 1%;
}

.customPeriodNumber {
    max-width: 20%;
    height: 40px !important;
    margin-right: 1%;
}

.customPeriodUnit {
    max-width: 20%;
}

.home-card {
    margin: auto;
    width: 50%;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 12px;
    list-style: none;
}

.item {
    min-height: 100%;
    background-color: #eee;
}

.deleteSchedule {
    margin: auto;
    float: right !important;
    margin-top: -20px;
}

.scheduleData {
    overflow-wrap: break-word;
}

.select-options {
    margin: 0%;
    font-size: 14px;
}

.warning {
    font-size: 12px;
    color: #CF5358;
    margin-top: 2px;
    margin-bottom: 2px;
}

li:not(:last-child) {
    margin-bottom: 5px;
}

.App-logo {
    height: 10rem;
    pointer-events: none;
}

li {
    width: 100%;
    position: relative;
}

.nowReportButton {
    margin-top: 30px;
}


p {
    font-family: 'Nunito Sans';
    font-size: 14px;
    color: rgb(32, 44, 68);
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: Heartbit infinite 4s linear;
    }
}

@keyframes Heartbit {
    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}